module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"morning-star-cu","short_name":"morning_star CU","start_url":"/","background_color":"#FFFFFF","theme_color":"#9e012e","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7b1eac63b011838c3de77bf55ec1ab0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
